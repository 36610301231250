.coffeeBg {
  height: 120px;
  width: auto;
  max-width: 100vw;
}
.about-link {
  font-size: 14px;
}
@media (min-width: 650px) {
  .about-link {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .coffeeBg {
    width: 100%;
    height: auto;
  }
}
