.productImageContainer {
  height: 300px;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .productImageContainer {
    height: 450px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .productImageContainer {
    height: 200px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .productImageContainer {
    height: 280px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .productImageContainer {
    height: 300px;
  }
}
