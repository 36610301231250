.order-tabs {
  a.nav-item {
    border-radius: 0;
    position: relative;
    margin-right: 10px;
    padding-right: 10px;
    &:not(:first-child) {
      padding-left: 30px;
      margin-left: -10px;
    }
    &.active {
      color: map-get($theme-colors, "brown");
    }
    &.active:not(:first-child):after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
    &.active:before {
      content: "";
      position: absolute;
      right: -20px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid map-get($theme-colors, "primary");
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
  }
}
