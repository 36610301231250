// Override default variables before the import
$body-bg: white;

$theme-colors: (
  "primary": #caf17c,
  "primary-text": #7fad23,
  "secondary": #e8e8e8,
  "light": #dadada,
  "primary-dark": #7eb415,
  "danger": #ff6e6e,
  "brown": #3b2626,
  "brown-text": #bdabaa,
  "gray": whitesmoke,
  "gray-text": #b1b1b1,
  "dark-green": #3b5802
);

$white: white;
$black: black;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "./modal-slide.scss";
@import "./orders-tab.scss";

@import url("https://fonts.googleapis.com/css?family=Molengo|Quicksand&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Quicksand", Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  color: map-get($theme-colors, "brown");
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
label,
button,
.heading,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Gill Sans", "Molengo", Helvetica, Arial, sans-serif;
}

p,
span,
li,
.p {
  font-family: "Quicksand", Helvetica, Arial, sans-serif;
}

.btn:focus {
  box-shadow: none;
}

button:focus {
  outline: none;
}

.text-primary-text {
  color: map-get($theme-colors, "primary-text");
}

.text-primary-dark {
  color: map-get($theme-colors, "primary-dark");
}

.text-brown-text {
  color: map-get($theme-colors, "brown");
}

.text-brown {
  color: map-get($theme-colors, "brown-text");
}

.bg-primary {
  background-color: map-get($theme-colors, "primary");
}

.bg-primary-text {
  background-color: map-get($theme-colors, "primary-text");
}

.bg-primary-dark {
  background-color: map-get($theme-colors, "primary-dark");
}

.bg-brown {
  background-color: map-get($theme-colors, "brown");
}

.btn-primary,
.btn-primary:hover,
.btn-outline-primary:hover,
.btn-gray:hover {
  color: map-get($theme-colors, "primary-text");
}
.btn-gray {
  border: 1px solid rgb(230, 230, 230);
}
.btn-gray:hover {
  background-color: map-get($theme-colors, "primary");
  border-color: map-get($theme-colors, "primary");
}
.btn-outline-primary {
  background-color: map-get($theme-colors, "gray");
  border-color: #e6e6e6;
  color: #727272;
}
.btn-primary-link, .btn-primary-link:hover {
  text-decoration: underline;
}
.btn-disabled,
.btn-disabled:hover {
  border-color: #e6e6e6;
  color: #e6e6e6;
}
.link-primary-dark {
  color: map-get($theme-colors, "primary-dark");
  text-decoration: underline;
}
.link-primary-dark:hover {
  color: map-get($theme-colors, "primary");
}

.modal-backdrop
{
    opacity:0.75 !important;
}

.wide-text {
  letter-spacing: 10px;
}

div.page-header-container {
  h2 {
    color: map-get($theme-colors, "primary-dark");
  }
}

div.shop-category-filter-container {
  h5 {
    color: map-get($theme-colors, "primary-dark");
  }
}
div.modal-content {
  border-radius: 15px;
  div.modal-header {
    background-color: $white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    .modal-title,
    button {
      color: map-get($theme-colors, "primary-dark");
    }
  }
  &.modal-cart {
    border-radius: 0;
    div.modal-header {
      border-radius: 0;
      position: fixed;
      width: 100%;
      z-index: 3;
      background-color: whitesmoke;
      -moz-box-shadow: 10px 0px 15px 0px #9e9e9e;
      -webkit-box-shadow: 10px 0px 15px 0px #9e9e9e;
      box-shadow: 10px 0px 15px 0px #9e9e9e;
    }
    div.modal-body {
      padding-top: 85px;
      .card-body {
        padding: 10px;
      }
    }
    div.modal-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 3;
      background-color: whitesmoke;
      justify-content: space-between;
      -moz-box-shadow: 10px 0px 15px 0px #c3c3c3;
      -webkit-box-shadow: 10px 0px 15px 0px #c3c3c3;
      box-shadow: 10px 0px 15px 0px #c3c3c3;
    }
  }
}
