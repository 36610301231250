.featuredProductImageContainer {
  height: 300px;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .featuredProductImageContainer {
    height: 200px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .featuredProductImageContainer {
    /* height: 150px; */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .featuredProductImageContainer {
    height: 200px;
  }
}
