.container {
  max-width: 960px;
}

/*
 * Custom translucent site header
 */

.navbar {
  background-color: rgba(126, 180, 22, 0.6);
  /* background-color: rgba(27, 22, 22, 0.8); */
  /* background-color: rgba(34, 51, 0, 0.85); */
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.navbar a {
  color: #999;
  transition: ease-in-out color .15s;
}
.navbar a:hover {
  color: #fff;
  text-decoration: none;
}
.navbar .nav-link {
  padding: 0;
}


/*
 * Extra utilities
 */

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1;
  }
}

.overflow-hidden { overflow: hidden; }