.cartImageContainer {
  height: 50px;
}
.removeButtonContainer {
  justify-content: flex-end;
  align-items: flex-start;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .cartImageContainer {
    height: 100px;
  }
  .removeButtonContainer {
    justify-content: flex-start;
    align-items: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .cartImageContainer {
    height: 95px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.cartModalImageContainer {
  height: 120px;
}
